import { createAction, union } from '@ngrx/store';
import {
  zefActionFailPayload,
  zefActionPayload,
  zefActionSuccessPayload
} from '@zerops/zef/core';
import { User } from './user-base.model';

export const setUserData = createAction(
  '[@zerops/backoffice/user-base] set-user-data',
  (data: User) => ({ data })
);

// -- login to zerops
export const loginToZerops = createAction(
  '[@zerops/backoffice/user-base] login-to-zerops',
  zefActionPayload<string>()
);

export const loginToZeropsFail = createAction(
  '[@zerops/backoffice/user-base] login-to-zerops/fail',
  zefActionFailPayload
);

export const loginToZeropsSuccess = createAction(
  '[@zerops/backoffice/user-base] login-to-zerops/success',
  zefActionSuccessPayload<string>()
);

const all = union({
  setUserData,
  loginToZerops,
  loginToZeropsFail,
  loginToZeropsSuccess
});
export type UserBaseActionUnion = typeof all;
