import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '@zerops/backoffice/app';
import { selectUserBaseData } from '@zerops/backoffice/core/user-base';
import { onWebsocketMessageDispatchAddRemoveEntities, onWebsocketMessageDispatchUpdateEntities } from '@zerops/zef/entities';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ClientStatusEntity } from './client-status-base.entity';

@Injectable()
export class ClientStatusBaseEffect {

  // client status list / update
  private _setupClientStatusListStream$ = createEffect(() => this._store.pipe(select(selectUserBaseData)).pipe(
    filter((d) => !!d),
    distinctUntilChanged(),
    map(() => this._clientStatusEntity.listSubscribe(
      undefined,
      undefined,
      {
        search: [
          {
            name: 'clientInfoJsonObject.status',
            operator: 'eq',
            value: 'ACTIVE'
          }
        ]
      }
    ))
  ));

  private _setupClientStatusUpdateStream$ = createEffect(() => this._store.pipe(select(selectUserBaseData)).pipe(
    filter((d) => !!d),
    distinctUntilChanged(),
    map(() => this._clientStatusEntity.updateSubscribe(undefined)
  )));

  private _onClientStatusListChangeStream$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchAddRemoveEntities(
      this._clientStatusEntity
    )
  ));

  private _onClientStatusUpdateChangeStream$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchUpdateEntities(this._clientStatusEntity)
  ));

  constructor(
    private _store: Store<AppState>,
    private _clientStatusEntity: ClientStatusEntity,
    private _actions$: Actions
  ) { }
}
