import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInterceptorInterceptor } from './api-interceptor.interceptor';

@NgModule({
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: ApiInterceptorInterceptor,
    multi: true
  }]
})
export class ApiInterceptorModule { }
