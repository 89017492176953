<mat-card class="__card  z-manual-elevation  c-soft-elevation">
  <ng-container *ngFor="let item of data; trackBy: trackByIndex;">

    <ng-container *ngIf="item.originalError.status === 403">
      <div class="__content">

        <h3 class="__title">You have been logged out</h3>

        <p class="__desc">Your session expired and we weren't able to refresh it, please login again.</p>

        <button
          mat-stroked-button
          type="button"
          (click)="onLogout$.next()"
          class="c-button--full  c-button--larger">

          Go to login

          <mat-icon>navigate_next</mat-icon>

        </button>

      </div>
    </ng-container>

    <div class="__content">

      <div class="__icon-wrap">
        <mat-icon class="__icon">sentiment_dissatisfied</mat-icon>
      </div>

      <h3 class="__title">
        Couldn't load vital parts of the application
      </h3>

      <p class="__desc">
        Our servers are most likely down. And we are terribly sorry about that. Please try again later, or contact our support.
      </p>

      <a
        href=""
        class="c-button--full  __button"
        mat-stroked-button>
        Try again now.
      </a>

    </div>

  </ng-container>
</mat-card>
