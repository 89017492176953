import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import { User } from '@zerops/backoffice/core/user-base';

@Component({
  /* eslint-disable-next-line @angular-eslint/component-selector */
  selector: 'zb-app-bar',
  templateUrl: './app-bar.container.html',
  styleUrls: [ './app-bar.container.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
/* eslint-disable-next-line @angular-eslint/component-class-suffix */
export class AppBarContainer {

  @Input()
  user: User;

  @Input()
  active: boolean;

  @Input()
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  menuAnchor: any;

  @Output()
  menuClicked = new EventEmitter<void>();

}
