import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './menu.constant';

const en = {
  dashboard: 'Dashboard',
  clients: 'Clients',
  projects: 'Projects',
  payments: 'Payments',
  settings: 'Settings',
  logout: 'Logout'
};

export type MenuTranslations = typeof en;

@NgModule()
export class MenuTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
