import { NgModule } from '@angular/core';
import { ZefPermissionService } from '@zerops/zef/permission';
import { zefSelectAuthState, ZefAuthState, zefSelectAuthData } from '@zerops/zef/auth';
import { Store, select } from '@ngrx/store';
import { map, distinctUntilChanged, filter } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { AppState } from './app.model';

export interface BackofficeAuthData {
  accessToken: string;
  expiresAt: string;
  expiresIn: number;
  firstName: string;
  lastName: string;
  refreshToken: string;
  refreshTokenExpiresAt: string;
  refreshTokenExpiresIn: number;
  tokenType: string;
  type: string;
}

/* eslint-disable no-unused-vars */
export enum Roles {
  Authorized = 'authorized',
  Incomplete = 'incomplete',
  Unauthorized = 'unauthorized'
}
/* eslint-enable no-unused-vars */

@NgModule({})
export class AppPermissionsModule {
  constructor(
    private _store: Store<AppState>,
    private _permissions: ZefPermissionService
  ) {

    // # Permissions Setup
    // base roles
    this._permissions.define(
      Roles.Authorized,
      () => combineLatest([
        this._store.pipe(select(zefSelectAuthState)).pipe(filter((s) => s !== ZefAuthState.Checking)),
        this._store.pipe(select(zefSelectAuthData))
      ]).pipe(
        map(([ state, data ]: [ ZefAuthState, BackofficeAuthData ]) => state === ZefAuthState.Authorized && !!data?.accessToken),
        distinctUntilChanged()
      )
    );

    this._permissions.define(
      Roles.Unauthorized,
      () => combineLatest([
        this._store.pipe(select(zefSelectAuthState)).pipe(filter((s) => s !== ZefAuthState.Checking)),
        this._store.pipe(select(zefSelectAuthData))
      ]).pipe(
        map(([ state, data ]: [ ZefAuthState, BackofficeAuthData ]) => !!(state === ZefAuthState.Invalid && !data?.accessToken)),
        distinctUntilChanged()
      )
    );

  }
}
