import { Routes } from '@angular/router';
import { zefPermissionGuardActivation, createRule } from '@zerops/zef/permission';
import { zefNgrxRouterGuardDeactivation } from '@zerops/zef/ngrx-router';
import { Roles } from './app.permissions';
import { RouteKeys } from './app.constant';

const authorizedRole = (redirect = '/login') => ({
  zefPermissions: createRule(
    { only: [ Roles.Authorized ] },
    [ redirect ]
  )
});

const unauthorizedRole = (redirect = '/dashboard') => ({
  zefPermissions: createRule(
    { only: [ Roles.Unauthorized ] },
    [ redirect ]
  )
});

export const appRoutes: Routes = [
  // login
  {
    path: 'login',
    loadChildren: () => import('../pages/+login/login.module').then(m => m.LoginModule),
    canActivate: [ zefPermissionGuardActivation ],
    data: {
      ...unauthorizedRole(),
      key: RouteKeys.Login
    }
  },

  // dashboard
  {
    path: 'dashboard',
    loadChildren: () => import('../pages/+dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [ zefPermissionGuardActivation ],
    canDeactivate: [ zefNgrxRouterGuardDeactivation ],
    data: {
      ...authorizedRole(),
      key: RouteKeys.Dashboard
    }
  },

  // clients
  {
    path: 'clients',
    loadChildren: () => import('../pages/+clients/clients.module').then(m => m.ClientsModule),
    canActivate: [ zefPermissionGuardActivation ],
    canDeactivate: [ zefNgrxRouterGuardDeactivation ],
    data: {
      ...authorizedRole(),
      key: RouteKeys.Clients
    }
  },

  // default route handler
  {
    path: '**',
    redirectTo: '/clients'
  }
];
