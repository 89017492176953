import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
  LOCALE_ID
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideHttpClient,
  withJsonpSupport
} from '@angular/common/http';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { translocoConfig, provideTransloco } from '@ngneat/transloco';
import { provideTranslocoLocale } from '@ngneat/transloco-locale';
import { provideTranslocoMessageformat } from '@ngneat/transloco-messageformat';
import { environment } from '@zerops/backoffice/env';
import { appRoutes } from './app.routes';
import { AppEffect } from './app.effect';
import { CoreModule } from '@zerops/backoffice/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/legacy-tooltip';
import { storeLogger } from '@zerops/ngrx-store-logger';
import { CommonModule } from '@angular/common';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { HttpClientModule } from '@angular/common/http';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { ZefErrorsModule } from '@zerops/zef/errors';
import { SnackSuccessModule } from '@zerops/zui/snack-success';
import { AppPermissionsModule } from './app.permissions';

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function logger(reducer: ActionReducer<any>): any {
  return storeLogger({
    collapsed: true,
    filter: {
      blacklist: [ '@ngrx/store/update-reducers' ]
    }
  })(reducer);
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const metaReducers: MetaReducer<any>[] = !environment.production
  ? [ logger ]
  : [];

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(
      appRoutes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'top'
      })
    ),
    provideHttpClient(
      withJsonpSupport()
    ),
    importProvidersFrom(
      CommonModule,
      MatCardModule,
      HttpClientModule,
      AppPermissionsModule,
      StoreModule.forRoot({}, { metaReducers }),
      EffectsModule.forRoot([ AppEffect ]),
      CoreModule,
      ZefErrorsModule.forRoot(),
      SnackSuccessModule
    ),
    provideTransloco({
      config: translocoConfig({
        availableLangs: ['en'],
        defaultLang: 'en',
        prodMode: !isDevMode()
      })
    }),
    provideTranslocoMessageformat({
      locales: 'en-US'
    }),
    provideTranslocoLocale({
      langToLocaleMapping: {
        en: 'en-US'
      }
    }),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'always'
      }
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        disableTooltipInteractivity: true
      }
    },
    {
        provide: LOCALE_ID,
        useValue: 'cs'
    },
    {
      provide: MAT_DATE_LOCALE,
      useExisting: LOCALE_ID
    }
  ]
};
