import { Injectable } from '@angular/core';
import { zefGo } from '@zerops/zef/ngrx-router';
import { zefCheckStoredTokenSuccess, zefLoginSuccess, zefLogout, zefSelectAuthData, zefSetToken } from '@zerops/zef/auth';
import { zefResetState } from '@zerops/zef/core';
import { zefWebsocketAuth, ZefWebsocketService, zefWebsocketTerminate, zefWebsocketClosed } from '@zerops/zef/websocket';
import { setUserData } from '@zerops/backoffice/core/user-base';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from './app.model';
import { BackofficeAuthData } from './app.permissions';

@Injectable()
export class AppEffect {

  private _onAnyAuthClear$ = this._actions$.pipe(ofType(zefLogout));
  private _onSetToken$ = this._actions$.pipe(ofType(zefSetToken));

  private _onLoginSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(zefLoginSuccess),
    map(() => zefGo([ '/clients' ]))
  ));

  private _onLoginSuccessGenerateReceiverId$ = createEffect(() => this._onSetToken$.pipe(
    tap(() => this._wService.generateReceiverId())
  ), { dispatch: false });

  private _onZefSetTokenAuthWebsocket$ = createEffect(() => this._onSetToken$.pipe(
    withLatestFrom(this._store.pipe(select(zefSelectAuthData))),
    map(([ _, auth ]) => zefWebsocketAuth({
      token: auth.accessToken,
      receiverId: this._wService.getReceiverId()
    }, { type: 'noop' }))
  ));

  private _onZefCheckStoredSuccessAuthWebsockets$ = createEffect(() => this._actions$.pipe(
    ofType(zefCheckStoredTokenSuccess),
    map(({ accessToken }) => zefWebsocketAuth({ token: accessToken, receiverId: this._wService.getReceiverId() }, { type: 'noop' }))
  ));

  private _onCheckStoredTokenSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(zefCheckStoredTokenSuccess),
    map((data) => data as BackofficeAuthData),
    map((d) => setUserData({ firstName: d.firstName, lastName: d.lastName }))
  ));

  private _onZefWsClosed$ = createEffect(() => this._actions$.pipe(
    ofType(zefWebsocketClosed),
    withLatestFrom(this._store.pipe(select(zefSelectAuthData))),
    filter(([ _, auth ]) => !!auth),
    map(([ _, auth ]) => zefWebsocketAuth({ token: auth.accessToken, receiverId: this._wService.getReceiverId() }, { type: 'noop' }))
  ));

  private _onLogoutRedirect$ = createEffect(() => this._onAnyAuthClear$.pipe(
    map(() => zefGo([ '/login' ]))
  ));

  private _onLogoutResetState$ = createEffect(() => this._onAnyAuthClear$.pipe(
    map(() => zefResetState())
  ));

  private _onLogoutWebsocketTerminate$ = createEffect(() => this._onAnyAuthClear$.pipe(
    map(() => zefWebsocketTerminate())
  ));

  constructor(
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    private _actions$: Actions<any>,
    private _store: Store<AppState>,
    private _wService: ZefWebsocketService
  ) { }
}
