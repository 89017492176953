import { NgModule, ModuleWithProviders } from '@angular/core';
import {
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule
} from '@ngneat/transloco';
// import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';

@NgModule({
  imports: [
    TranslocoModule,
    // TranslocoMessageFormatModule.forRoot()
  ]
})
export class ZefTranslationsModule {
  static forRoot(production: boolean): ModuleWithProviders<ZefTranslationsModule> {
    return {
      ngModule: ZefTranslationsModule,
      providers: [
        {
          provide: TRANSLOCO_CONFIG,
          useValue: translocoConfig({
            prodMode: production,
            availableLangs: ['en'],
            defaultLang: 'en'
          })
        }
      ]
    };
  }
}
