import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { environment } from '@zerops/backoffice/env';
import { Observable } from 'rxjs';

@Injectable()
export class ApiInterceptorInterceptor implements HttpInterceptor {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>  {
    if (request.url.startsWith('/api') && !request.url.includes(environment.apiUrl)) {
      request = request.clone({
        url: environment.apiUrl + (request.url.replace('/api', ''))
      });
    }
    return next.handle(request);
  }
}
