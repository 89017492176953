import { createReducer, on } from '@ngrx/store';
import { zefResetState } from '@zerops/zef/core';
import { UserBaseState } from './user-base.model';
import {
  UserBaseActionUnion,
  setUserData
} from './user-base.action';

const initialState = new UserBaseState();

const actionReducer = createReducer(
  initialState,
  on(
    setUserData,
    (s, { data }): UserBaseState => ({
      ...s,
      data
    })
  ),
  on(zefResetState, () => initialState)
);

export function userBaseReducer(
  state: UserBaseState,
  action: UserBaseActionUnion
) {
  return actionReducer(state, action);
}
