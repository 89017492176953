/* eslint-disable no-unused-vars */
export enum RouteKeys {
  Login = 'login',
  Dashboard = 'dashboard',
  Clients = 'clients'
}

export enum ApiEntityKeys {
  ClientStatus = 'client-status',
  ClientUser = 'client-user'
}
/* eslint-enable no-unused-vars */

export const DEFAULT_APP_ROUTE = [ '/', 'dashboard', {} ];
