import { NgModule } from '@angular/core';
import { SnackErrorModule } from '@zerops/zui/snack-error';
import { SnackSuccessModule } from '@zerops/zui/snack-success';
import { SnackWarningModule } from '@zerops/zui/snack-warning';
import { SnackInfoModule } from '@zerops/zui/snack-info';
import { DialogErrorModule } from './dialog-error';
import { PopErrorModule } from './pop-error';
import { AppBarModule } from './app-bar';
import { MenuModule } from './menu';
import { FooterModule } from './footer';

@NgModule({
  exports: [
    DialogErrorModule,
    PopErrorModule,
    SnackErrorModule,
    SnackSuccessModule,
    SnackWarningModule,
    SnackInfoModule,
    AppBarModule,
    FooterModule,
    MenuModule
  ]
})
export class FeatureModule {

}
