
<zb-app-bar
  *ngIf="state.authState !== authStates.Checking && !!state.user"
  (menuClicked)="menuPopRef.open()"
  [user]="state.user"
  [active]="true"
  [menuAnchor]="menuPopRef"
  class="__app-bar">
</zb-app-bar>

<sat-popover
  #menuPopRef
  [autoFocus]="false"
  [restoreFocus]="false"
  hasBackdrop
  [closeTransition]="'450ms 50ms cubic-bezier(0.4, 0.0, 0.2, 1)'"
  (backdropClicked)="menuPopRef.close()"
  [verticalAlign]="'below'"
  [horizontalAlign]="'center'">
  <mat-card class="__menu-card  z-manual-elevation  c-soft-elevation">
    <zb-menu (menuClicked)="menuPopRef.close()"></zb-menu>
  </mat-card>
</sat-popover>

<div class="__content u-position--relative">

  <router-outlet #outlet="outlet"></router-outlet>

  <zb-footer class="__footer"></zb-footer>

</div>

<zb-pop-error
  *zefPopErrorOutlet="let data = data; let instance = instance;"
  [data]="data"
  [instance]="instance">
</zb-pop-error>

<zui-snack-error
  *zefSnackErrorOutlet="let data = data; let instance = instance;"
  [data]="data"
  [instance]="instance">
</zui-snack-error>

<zui-snack-success
  *zefSnackOutlet="let data = data; let instance = instance; type: 'success'"
  [data]="data"
  [instance]="instance">
</zui-snack-success>

<zui-snack-warning
  *zefSnackOutlet="let data = data; let instance = instance; type: 'warning'"
  [data]="data"
  [instance]="instance">
</zui-snack-warning>

<zui-snack-info
  *zefSnackOutlet="let data = data; let instance = instance; type: 'info'"
  [data]="data"
  [instance]="instance">
</zui-snack-info>

<zb-dialog-error
  *zefDialogErrorOutlet="let data = data"
  [data]="data">
</zb-dialog-error>

<zef-dialog-error></zef-dialog-error>
