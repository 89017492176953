export class UserBaseState {
  data: User;

  constructor() {
    this.data = undefined;
  }
}

export interface User {
  firstName: string;
  lastName: string;
}
