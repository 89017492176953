<zef-fade-animation
  [animationState]="active"
  [animationParams]="{
    enterDuration: '650ms',
    leaveDuration: '200ms',
    easing: 'cubic-bezier(0.4, 0.0, 0.2, 1)'
  }">
  <div class="__grid">

    <div class="__grid-item">
      <a [routerLink]="[ '/dashboard' ]">
        <zui-logo class="__logo"></zui-logo>
      </a>
    </div>


    <div class="u-text--right  __grid-item">

      <zef-avatar
        class="__avatar"
        [size]="40"
        [name]="user?.firstName + ' ' + user?.lastName"
        [backgroundColor]="'#4c575c'">
      </zef-avatar>

      <button
        mat-button
        type="button"
        [satPopoverAnchor]="menuAnchor"
        (click)="menuClicked.emit()"
        class="__menu-button">
        <mat-icon class="__icon">menu</mat-icon>
      </button>

    </div>

  </div>
</zef-fade-animation>
