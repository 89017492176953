import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  EntityService,
  CollectionManagerService
} from '@zerops/zef/entities';
import { AppState, ApiEntityKeys } from '@zerops/backoffice/app';
import { ClientStatus } from '@zerops/models/client-status';
import { ZefWebsocketService } from '@zerops/zef/websocket';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ClientStatusEntity extends EntityService<ClientStatus> {

  entityByClientId$ = (clientId: string) => this.list$()
    .pipe(
      map((d) => d?.find((cs) => cs.clientId === clientId))
    );

  constructor(
    public store: Store<AppState>,
    public collectionManager: CollectionManagerService,
    public websocketService: ZefWebsocketService
  ) {
    super(ApiEntityKeys.ClientStatus, store, collectionManager, websocketService);
  }

}
