import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterContainer } from './footer.container';
import { FooterTranslationsModule } from './footer.translations';

@NgModule({
  declarations: [ FooterContainer ],
  imports: [
    CommonModule,
    FooterTranslationsModule
  ],
  exports: [ FooterContainer ]
})
export class FooterModule {

}
