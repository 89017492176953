import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ZefError } from '@zerops/zef/errors';
import { zefLogout } from '@zerops/zef/auth';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  /* eslint-disable-next-line @angular-eslint/component-selector */
  selector: 'zb-dialog-error',
  templateUrl: './dialog-error.container.html',
  styleUrls: [ './dialog-error.container.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
/* eslint-disable-next-line @angular-eslint/component-class-suffix */
export class DialogErrorContainer extends ZefReactiveComponent {
  // # Event Streams
  onLogout$ = new Subject<void>();

  // # Data
  // -- sync

  // -- angular
  @Input()
  data: ZefError[];

  // # Action Streams
  private _logoutAction$ = this.onLogout$.pipe(
    map(() => zefLogout())
  );

  constructor() {
    super();

    this.$dispatchActions([ this._logoutAction$ ]);
  }
}
