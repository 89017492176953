import { NgModule } from '@angular/core';
import { ZefLibModule } from './zef-lib';
import { UserBaseModule } from './user-base';
import { ClientStatusBaseModule } from './client-status-base';
import { ApiInterceptorModule } from './api-interceptor';

@NgModule({
  imports: [
    ZefLibModule,
    UserBaseModule,
    ClientStatusBaseModule,
    ApiInterceptorModule
  ]
})
export class CoreModule {

}
