<mat-nav-list class="__list">

  <a
    [routerLink]="[ '/', 'dashboard' ]"
    class="__item"
    (click)="menuClicked.emit()"
    mat-list-item>
    <zui-action-list-item
      [icon]="'grid_view'"
      [label]="state.translations.dashboard">
    </zui-action-list-item>
  </a>

  <a
    [routerLink]="[ '/', 'clients' ]"
    class="__item"
    (click)="menuClicked.emit()"
    mat-list-item>
    <zui-action-list-item
      [icon]="'people_outline'"
      [label]="state.translations.clients">
    </zui-action-list-item>
  </a>

  <a
    [routerLink]="[ '/', 'projects' ]"
    class="__item"
    (click)="menuClicked.emit()"
    disabled
    mat-list-item>
    <zui-action-list-item
      [icon]="'article'"
      [label]="state.translations.projects">
    </zui-action-list-item>
  </a>

  <a
    [routerLink]="[ '/', 'payments' ]"
    class="__item"
    (click)="menuClicked.emit()"
    disabled
    mat-list-item>
    <zui-action-list-item
      [icon]="'paid'"
      [label]="state.translations.payments">
    </zui-action-list-item>
  </a>

  <a
    [routerLink]="[ '/', 'settings' ]"
    class="__item"
    (click)="menuClicked.emit()"
    disabled
    mat-list-item>
    <zui-action-list-item
      [icon]="'settings'"
      [label]="state.translations.settings">
    </zui-action-list-item>
  </a>

  <mat-divider></mat-divider>

  <a
    class="__item"
    (click)="menuClicked.emit(); onLogout$.next();"
    mat-list-item>
    <zui-action-list-item
      [icon]="'logout'"
      [label]="state.translations.logout">
    </zui-action-list-item>
  </a>

</mat-nav-list>
