import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { ActionListItemModule } from '@zerops/zui/action-list-item';
import { MenuContainer } from './menu.container';
import { MenuTranslationsModule } from './menu.translations';

@NgModule({
  declarations: [ MenuContainer ],
  imports: [
    CommonModule,
    RouterModule,
    MatListModule,
    MatIconModule,
    MatDividerModule,
    ActionListItemModule,
    MenuTranslationsModule
  ],
  exports: [ MenuContainer ]
})
export class MenuModule {

}
