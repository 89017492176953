import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ZefFadeAnimationModule } from '@zerops/zef/animations';
import { ZefAvatarModule } from '@zerops/zef/avatar';
import { SatPopoverModule } from '@zerops/zef/popover';
import { ZuiLogoModule } from '@zerops/zui/logo';
import { AppBarContainer } from './app-bar.container';

@NgModule({
  declarations: [ AppBarContainer ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    RouterModule,
    ZefFadeAnimationModule,
    ZefAvatarModule,
    SatPopoverModule,
    ZuiLogoModule
  ],
  exports: [ AppBarContainer ]
})
export class AppBarModule {

}
