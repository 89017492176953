import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { zefLoginSuccess } from '@zerops/zef/auth';
import { BackofficeAuthData } from '@zerops/backoffice/app/app.permissions';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { loginToZerops, loginToZeropsFail, loginToZeropsSuccess, setUserData } from './user-base.action';
import { UserBaseApi } from './user-base.api';
import { environment } from '@zerops/backoffice/env';

@Injectable()
export class UserBaseEffect {

  private _onSetToken$ = createEffect(() => this._actions$.pipe(
    ofType(zefLoginSuccess),
    map(({ data }: { data: BackofficeAuthData }) => setUserData({ firstName: data.firstName, lastName: data.lastName }))
  ));

  private _onLoginToZerops$ = createEffect(() => this._actions$.pipe(
    ofType(loginToZerops),
    switchMap((action) => this._api
      .loginToZerops$(action.data)
      .pipe(
        map((res) => loginToZeropsSuccess(res.refreshToken, action)),
        catchError((err) => of(loginToZeropsFail(err, action)))
      )
    )
  ));

  private _onLoginToZeropsSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(loginToZeropsSuccess),
    tap(({ data }) => window.open(`${environment.loginToZeropsUrl}?${btoa('refreshToken=' + data)}`, '_blank'))
  ), { dispatch: false });

  constructor(
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    private _actions$: Actions<any>,
    private _api: UserBaseApi
  ) { }
}
