import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { zefSelectAuthState } from '@zerops/zef/auth';
import { selectUserBaseData } from '@zerops/backoffice/core/user-base';
import { AppState } from './app.model';
import { ZefAuthState } from '@zerops/zef/auth/auth.model';
import { SatPopoverModule } from '@zerops/zef/popover';
import { NgIf, registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import { RouterOutlet } from '@angular/router';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FeatureModule } from '../features';
import { ZefErrorsModule } from '@zerops/zef/errors';
import { ZefSnackModule } from '@zerops/zef/snack';

registerLocaleData(localeCs, 'cs');

const en = {
  true: 'Yes',
  false: 'No',
  clientStatuses: {
    ACTIVE: 'Active'
  },
  close: 'Close'
};

export type GeneralTranslations = typeof en;

@Component({
  /* eslint-disable-next-line @angular-eslint/component-selector */
  selector: 'zb-app',
  templateUrl: './app.feature.html',
  styleUrls: ['./app.feature.scss'],
  standalone: true,
  imports: [
    SatPopoverModule,
    RouterOutlet,
    MatCardModule,
    FeatureModule,
    ZefErrorsModule,
    ZefSnackModule,
    NgIf
  ]
})
/* eslint-disable-next-line @angular-eslint/component-class-suffix */
export class AppFeature extends ZefReactiveComponent {

  // # Data
  // -- sync
  authStates = ZefAuthState;

  // -- async
  authState$ = this._store.pipe(select(zefSelectAuthState));
  user$ = this._store.pipe(select(selectUserBaseData));

  // # State resolver
  state = this.$connect({
    authState: this.authState$,
    user: this.user$
  });

  constructor(
    private _store: Store<AppState>,
    private _translationsService: ZefTranslationsService
  ) {
    super();

    this._translationsService.setTranslations('general', { en });

  }
}
