import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class UserBaseApi {
  constructor(private _http: HttpClient) { }

  loginToZerops$(id: string) {
    return this._http.post<{ refreshToken: string; }>(
      `/api/user/${id}/login-to-zerops`,
      undefined
    );
  }

}
