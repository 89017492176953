import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FEATURE_NAME } from './user-base.constant';
import { UserBaseState } from './user-base.model';

export const selectUserBaseState = createFeatureSelector<UserBaseState>(FEATURE_NAME);

export const selectUserBaseData = createSelector(
  selectUserBaseState,
  (state) => state.data
);
