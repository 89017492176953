import { NgModule } from '@angular/core';
import { ZefEntitiesModule, CollectionManagerService } from '@zerops/zef/entities';
import { ZefErrorsModule } from '@zerops/zef/errors';
import { ZefProgressModule } from '@zerops/zef/progress';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { ZefAuthModule, ZefAuthRefreshTokenModule } from '@zerops/zef/auth';
import { ZefFormsModule } from '@zerops/zef/forms';
import { ZefPipesModule } from '@zerops/zef/pipes';
import { ZefPopErrorModule, ZefSnackErrorModule } from '@zerops/zef/errors';
import { ZefNgrxRouterModule } from '@zerops/zef/ngrx-router';
import { ZefTranslationsModule } from '@zerops/zef/translations';
import { ZefStateSwitcherModule } from '@zerops/zef/state-switcher';
import { META_CONFIG, ZefMetaModule } from '@zerops/zef/meta';
import { HttpStatusCodes } from '@zerops/zef/core';
import { environment } from '@zerops/backoffice/env';
import { ApiEntityKeys } from '@zerops/backoffice/app';
import { BackofficeAuthData } from '@zerops/backoffice/app';
import { ZefWebsocketModule } from '@zerops/zef/websocket';

@NgModule({
  imports: [
    ZefTranslationsModule.forRoot(environment.production),
    ZefEntitiesModule.forRoot({
      config: {
        apiPrefix: '/api'
      }
    }),
    // !!! keep order of AuthRefresh, Auth and Error imports
    // needed for the correct interceptor chain
    ZefAuthRefreshTokenModule,
    ZefErrorsModule.forRoot({
      // we don't want to show 401 error, as it always
      // triggers refresh, which either successes and
      // "nothing" visible happens for the user or fails
      // in which case single 403 error is added and handled
      // by a dialog error component
      blacklistedErrors: [ HttpStatusCodes.UNAUTHORIZED ],
      uniqueErrors: [ HttpStatusCodes.FORBIDDEN ]
    }),
    ZefProgressModule.forRoot(),
    ZefAuthModule.forRoot({
      tokenDataGetter: (response: BackofficeAuthData) => response,
      loginEndpoint: `${environment.apiUrl}/auth/login`,
      logoutEndpoint: `${environment.apiUrl}/auth/logout`,
      refreshEndpoint: `${environment.apiUrl}/auth/refresh`
    }),
    ZefWebsocketModule.forRoot({
      loginUrl: environment.websocketLoginUrl,
      host: environment.websocketHost,
      apiUrl: environment.websocketApiUrl,
      forceSecuredEndpoint: environment.forceSecuredWebsocket
    }),
    ZefDialogModule.forRoot(),
    ZefFormsModule.forRoot(),
    ZefNgrxRouterModule.forRoot(),
    ZefStateSwitcherModule.forRoot(),
    ZefPopErrorModule,
    ZefSnackErrorModule,
    ZefPipesModule,
    ZefMetaModule.forRoot()
  ],
  providers: [
    {
      provide: META_CONFIG,
      useValue: {
        baseTitle: 'Zerops backoffice',
        separator: '~'
      }
    },
  ]
})
export class ZefLibModule {
  constructor(private _collectionManagerService: CollectionManagerService) {
    this._collectionManagerService.create({
      [ApiEntityKeys.ClientStatus]: {},
      [ApiEntityKeys.ClientUser]: {
        config: {
          idAttribute: 'clientId'
        }
      }
  });
  }
}
