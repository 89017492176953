import { Component, EventEmitter, Output } from '@angular/core';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { MenuTranslations } from './menu.translations';
import { FEATURE_NAME } from './menu.constant';
import { Subject } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { zefLogout } from '@zerops/zef/auth';

@Component({
  /* eslint-disable-next-line @angular-eslint/component-selector */
  selector: 'zb-menu',
  templateUrl: './menu.container.html',
  styleUrls: [ './menu.container.scss' ]
})
/* eslint-disable-next-line @angular-eslint/component-class-suffix */
export class MenuContainer extends ZefReactiveComponent {

  // # Event Streams
  onLogout$ = new Subject<void>();

  // # Data
  // -- angular
  @Output()
  menuClicked = new EventEmitter<void>();

  // -- async
  translations$ = this.translate$<MenuTranslations>(FEATURE_NAME);

  // # State resolver
  state = this.$connect({
    translations: this.translations$
  }, false);

  // # Action Streams
  private _logoutAction$ = this.onLogout$.pipe(mapTo(zefLogout()));

  constructor() {
    super();

    // # Dispatcher
    this.$dispatchActions([ this._logoutAction$ ]);
  }
}
