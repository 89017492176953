import { Component, Input } from '@angular/core';
import { SatPopover } from '@zerops/zef/popover';
import { ZefError } from '@zerops/zef/errors';

@Component({
  /* eslint-disable-next-line @angular-eslint/component-selector */
  selector: 'zb-pop-error',
  templateUrl: './pop-error.container.html',
  styleUrls: [ './pop-error.container.scss' ]
})
/* eslint-disable-next-line @angular-eslint/component-class-suffix */
export class PopErrorContainer {
  @Input()
  data: ZefError[];

  @Input()
  instance: SatPopover;

  trackBy(index: number) {
    return index;
  }
}
