import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './footer.constant';

const en = {
};

export type FooterTranslations = typeof en;

@NgModule()
export class FooterTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
