import { Environment } from './environment.model';

export const environment: Environment = {
  production: true,
  envName: 'production',
  apiUrl: 'https://api.app-prg1.zerops.io/api/rest/backoffice',
  forceSecuredWebsocket: true,
  websocketLoginUrl: 'https://api.app-prg1.zerops.io/api/rest/backoffice/web-socket/login',
  websocketHost: 'api.app-prg1.zerops.io',
  websocketApiUrl: 'api/rest/backoffice/web-socket',
  loginToZeropsUrl: 'https://app.zerops.io/authorize'
};
