import {
  Component,
  ChangeDetectionStrategy
} from '@angular/core';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { FooterTranslations } from './footer.translations';
import { FEATURE_NAME } from './footer.constant';

@Component({
  /* eslint-disable-next-line @angular-eslint/component-selector */
  selector: 'zb-footer',
  templateUrl: './footer.container.html',
  styleUrls: [ './footer.container.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
/* eslint-disable-next-line @angular-eslint/component-class-suffix */
export class FooterContainer extends ZefReactiveComponent {

  // # Event Streams

  // # Data
  // -- sync
  currentYear = new Date().getFullYear();

  // -- async
  translations$ = this.translate$<FooterTranslations>(FEATURE_NAME);

  // -- angular

  // # State resolver
  state = this.$connect({
    translations: this.translations$
  });

}
